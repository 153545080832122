import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import CtaDisclosurechff from "src/components/ctadisclosurechff"

const spabmData = import("./data/data.json");

export default function spabm() {
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-color: #a0d1f9;
      background-image: url(/img/download-pdf.svg), linear-gradient(315deg,#a0d1f9,#709af8 90%);
      background-repeat: no-repeat, no-repeat;
      background-size: 400px, cover;
      background-attachment: fixed;
      background-position: bottom 20px left, center;
    }

    .footerlinks-module--br--2T_Dp,
     .footerlinks-module--br--2T_Dp a{
      color: rgba(255,255,255,0.6);
    }

    .trbm-module--trbm .trbm-module--wrap {
      height: 100%;
      border: 2px solid #31374C;
      padding: 20px;
    }

    .trbm-module--trbm .trbm-module--card {
      padding: 40px;
      margin-top: 60px;
      box-shadow: 0px 0px 15px #648ADE;
    }

    .trbm-module--trbm .trbm-module--bullets {
      list-style: disc;
      width: 75%;
      line-height: 150%;
    }

    .trbm-module--trbm button {
      background: #43ba19;
      font-weight: normal;
      padding: 20px 50px;
      font-size: 30px;
    }

    .trbm-module--trbm .trbm-module--steps, .trbm-module--trbm h2 {
      display: none;
    }

    `}
    </style>
    <title>Save any web page easily - Easy Save</title></Helmet>
    <section>
      <Trbm data={spabmData}>
        <CtaDisclosurechff></CtaDisclosurechff>
      </Trbm>
      </section>
    </HomepageLayout>
  )
}
